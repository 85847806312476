import {get, post} from '../../axiosCall'
import { manualAction } from './action'

export const getNotifRequest = async (path) => {
  const result = await get(path)
  return result.data
}

export const readNotificationRequest = async (path, body) => {
  const result = await post(path, body)
  return result.data
}

export const getBookingApi = async (path, body) => {
  const result = await post(path, body)
  return result.data
}

export const searchMe = async (path, body) => {
  const result = await post(path, body)
  return result.data
}

export const notificationCount = async (path, body) => {
  const result = await post(path, body)
  return result.data
}

export const manualGenerate = async (path, body, dispatch) => {
  const result = await post(path, body)
  dispatch(manualAction())
  return result.data
}