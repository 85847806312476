import {DELETE_CONTACT, INSERT_CONTACT, PREVIEW_DATA} from './constant'

export const deleteContact = (payload) => {
  return {
    type: DELETE_CONTACT,
    payload
  }
}

export const insertContact = (payload) => {
  return {
    type: INSERT_CONTACT,
    payload
  }
}

export const previewData = (payload) => {
  return {
    type: PREVIEW_DATA,
    payload
  }
}
