import axios from 'axios'

 export const api = process.env.REACT_APP_PRODUCTION_API || 'http://localhost/'
export const post = (path, data, file) => {
  const url = `${api}${path}`
  const result = axios({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    url,
    data 
  })

  return result
}

export const get = (path, params) => {
  const url = `${api}${path}`
  const result = axios.get(url, {
    params
  })

  return result
}

export const remove = (path, data) => {
  const url = `${api}${path}`
  const result = axios.delete(url, {
    data
  })

  return result
} 
