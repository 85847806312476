import {
  GET_NOTIFICATION_COUNT,
  GET_NOTIFICATION_CONTENT,
  MANUAL_GENERATE_ACTION,
  MANUAL_GENERATE_SUCCESS,
  MANUAL_GENERATE_ERROR,
  MANUAL_GENERATE_END
} from './constants'


export const getNotificationAction = (payload) => {
  return {
    type: GET_NOTIFICATION_COUNT,
    payload
  } 
}

export const getNotificationContentAction = (payload) => {
  return {
    type: GET_NOTIFICATION_CONTENT,
    payload
  } 
}

export const manualAction = () => {
  return {
    type: MANUAL_GENERATE_ACTION
  }
}

export const manualSuccess = (payload) => {
  return {
    type: MANUAL_GENERATE_SUCCESS,
    payload
  } 
}

export const manualError = (payload) => {
  return {
    type: MANUAL_GENERATE_ERROR,
    payload
  }
}

export const manualEnd = (payload) => {
  return {
    type: MANUAL_GENERATE_END,
  }
}