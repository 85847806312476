/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable no-cond-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useState, useEffect} from 'react'
import { useStateValue } from '../../stateContext'
import {useDropzone} from 'react-dropzone'
import {csvSeparator} from '../../helper'
import {postForm, deleteForm, deleteSched, checkContact } from './requestApi'
import Spinner from '../../component/Spinner'
import { 
  Paper, 
  Grid, 
  Button, 
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core'
import './index.css'
import { uploadCSVAction, deleteRecordOk, deleteRecordAction, uploadCSVOk, deleteRecordError, clearMessage,formInternalError } from './actions'

const Form = () => {
  const [{errorMessage, successMessage, loading, deleteLoadingMessage, insertLoadingMessage}, dispatch] = useStateValue()
  const [, setCsvData] = useState([])
  const [showDialog, setShowDialog] = useState(false)
  const [dialog, setDialog] = useState(false)
  const [filename, setFilename] = useState([])
  const [postCSVData, setPostCSVData] = useState([])
  const [limit, ] = useState(100)
  const [invalid, setInvalid] = useState("")
  const [showButton, setShowButton] = useState(false)
  const [contactCount, setContactCount] = useState(false)

  useEffect(() => {
    const contactCheck = async () => {
      const count = await checkContact('all-contacts', dispatch)  
      setContactCount(count)
    }
    contactCheck()
  }, [])

  const session = JSON.parse(window.sessionStorage.getItem('ci_cookies'))

  const onDrop = useCallback(acceptedFiles => {
    const data = new FormData();
    dispatch(clearMessage())
    setShowButton(true)
    setShowDialog(true)
    data.append('file', acceptedFiles[0])
    data.append('name', 'csv file')
    data.append('description', 'testing')
    setFilename(acceptedFiles[0].name)
    let reader = new FileReader()
    reader.readAsText(acceptedFiles[0])
    reader.onload = loadHeader
  }, [])

  const loadHeader = (e) => {
    const {target} = e
    const theLastArray = []
    const objectArray = []
    const data = csvSeparator(target.result, ",") || []
    data.map((e, k) => {
          theLastArray.push(e)
          var element = {}
          e.map((r, k) => {
            switch (k) {
              case 0:
                element.interpreter = {id: parseInt(r)}
                element.allDay = false
                break;
              case 1:
                const startSplit = r.split(" ")
                if (startSplit.length > 1) {
                  const timeTrim = startSplit[1].split(".")
                  const start = `${startSplit[0]}T${timeTrim[0]}Z`
                  element.startDate = start
                }
                break;
              case 2:
                const endSplit = r.split(" ")
                if (endSplit.length > 1) {
                  const endTrim = endSplit[1].split(".")
                  const end = `${endSplit[0]}T${endTrim[0]}Z`
                  element.endDate = end
                }
                break;
              case 3:
                element.type = {id: 10, name: "VRS"}
                break;
              default:
                return false 
            }

            return true
          })
          if (k === (data.length-1)) {
            setShowButton(false)
          }

          if (!!element.startDate && !!element.endDate) {
            objectArray.push(element)
            setInvalid("")
          } else {
            setInvalid('Please upload file with schedule')
          }
      return true
    })

    setPostCSVData(objectArray)
    setCsvData(theLastArray)
  }

  const onHandleSubmit = async () => {
    let initValue = 0
    let tempbatch = []
    let itemsCount
    
    const non_info = await deleteForm('auth/non-availability-info', {session, count: limit, page: 1}, dispatch)
    const {count} = non_info.data.result
    itemsCount = count
    let localcount = 1
    while(itemsCount > 0) {
      dispatch(deleteRecordAction("Cleaning up all non-availabity records. Please wait, it might take a moment"))
      const nonAvailabilityData = await deleteForm('auth/non-availability-info', {session, count: limit, page: localcount}, dispatch)
      const {items, count} = nonAvailabilityData.data.result
      itemsCount = count
      for (let ii=0; ii < items.length; ii++) {
        const directDelete = await deleteSched('auth/direct-delete', {session, id: items[ii].id}, dispatch)
        if (directDelete.data.status > 200) {
          dispatch(deleteRecordError("An error occured, please check your file and re-upload again"))
          break
        } 
      }
      localcount++
    }
    
    if (!errorMessage) {
      for (let i=0; i < (postCSVData.length/limit); i++) {
        dispatch(deleteRecordOk("Done cleaning up records!"))
        for (let ii=initValue; ii < limit*(i+1); ii++) {
          tempbatch.push(ii)
          try {
            const postingData = await postForm('auth/post-form', {postData: postCSVData[ii], session, count: ii+1, totalRecord: postCSVData.length}, dispatch)
            dispatch(uploadCSVAction(postingData.data.message))
          } catch(error) {
            dispatch(formInternalError("Internal error 500"))
            setPostCSVData([])
            setFilename() 
            setShowDialog(false)
            return false
          }

          initValue = ii
          if (ii === postCSVData.length -1) {
            setPostCSVData([])
            setFilename() 
            setShowDialog(false)
            dispatch(uploadCSVOk("Successfully updated all non-available records"))
            return true
          }
        }
      }
    }
  }

  const handleOpenDialog = () => {
    setDialog(true)
  }

  const handleCloseDialog = () => {
    setDialog(false)
  }

  const onHandleCancel = () => {
    setCsvData([])
    setFilename("")
    setShowDialog(false)
  }

  const {getInputProps} = useDropzone({onDrop, accept: '.csv'})
  return (<center>
    <Spinner loading={loading} message1={deleteLoadingMessage} message2={insertLoadingMessage}/>
    <Paper className="container">
      <Dialog onClose={() => handleCloseDialog()} open={dialog}>
        <DialogTitle>Preivew of 10 schedule to upload</DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table style={{width: 500}}>
                <TableHead>
                  <TableRow>
                    <TableCell>Interpreter</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Type ID</TableCell>
                    <TableCell>Type name</TableCell>
                    <TableCell>All Dy</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {postCSVData.length > 0 ? postCSVData.map((e, k) => {
                    if (k < 10) {
                      return (
                        <TableRow>
                          <TableCell>{e.interpreter.id || ""}</TableCell>
                          <TableCell>{e.startDate || ""}</TableCell>
                          <TableCell>{e.endDate || ""}</TableCell>
                          <TableCell>{e.type.id || ""}</TableCell>
                          <TableCell>{e.type.name || ""}</TableCell>
                          <TableCell>{e.allDay}</TableCell>
                        </TableRow>
                      )
                    }
                  }) : ''}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
      </Dialog>
      <Grid container spacing={3} alignItems="center" alignContent="center" justify="center">
        <p style={{color: "green"}}>
          {successMessage || ""}
        </p>
        <p style={{color: "red"}}>
          {errorMessage || ""}
        </p>
        {contactCount > 0 ? 
        <Grid item xs={12}>
          <input
            {...getInputProps()}
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
          />
          <label htmlFor="raised-button-file">
            <Button variant="raised" color="primary" component="span">
              Upload CSV
            </Button>
          </label> 
        </Grid>
          : <p>There is no contact in the database, your change will not be send to the host</p>}
        <Grid item xs={12}>
          {showButton ? <p><i>It might take some time to map the timezone data, please wait for the submit button to show</i></p> : ""}
          {showDialog ? <a href="#" onClick={() => handleOpenDialog()}>Preview of schedule to upload</a> : ""}
        </Grid>
        <Grid item xs={12}>
          <p>{filename}</p>
          <p style={{color: 'red'}} >{invalid}</p>
        </Grid>
        <Grid item xs={6} style={postCSVData.length > 0 ? {display: 'block'} : {display: 'none'}}>
          <Button 
            variant="contained" 
            color="primary" 
            id="submit-post-data"
            fullWidth
            onClick={() => onHandleSubmit()}
          >Submit</Button>
        </Grid>
        <Grid item xs={6} style={postCSVData.length > 0 ? {display: 'block'} : {display: 'none'}}>
          <Button 
            variant="contained" 
            fullWidth
            onClick={() => onHandleCancel()}
          >Cancel</Button>
        </Grid>
      </Grid>
    </Paper>
  </center>)
}

export default Form