import { 
  DELETE_RECORD_ACTION,
  DELETE_RECORD_END,
  DELETE_RECORD_ERROR,
  DELETE_RECORD_OK,
  POST_CSV_DATA_ACTION, 
  POST_CSV_DATA_ERROR, 
  POST_CSV_DATA_OK, 
  POST_CSV_DATA_END, 
  CLEAR_MESSAGE,
  CONTACT_COUNT,
  FORM_INTERNAL_ERROR
} from "../container/Form/constants"

import { DELETE_CONTACT, INSERT_CONTACT, PREVIEW_DATA } from "../container/UpdateContacts/constant"

import {
  GET_NOTIFICATION_CONTENT,
  GET_NOTIFICATION_COUNT,
  MANUAL_GENERATE_ACTION,
  MANUAL_GENERATE_END,
  MANUAL_GENERATE_ERROR,
  MANUAL_GENERATE_SUCCESS,
} from '../component/Header/constants'
import { SPINNER_KILLER_ACTION } from "../component/Spinner/constants"

export const initialState = {
  allContacts: [],
  cookies: "",
  deleteLoadingMessage: "",
  insertLoadingMessage: "",
  loading: false,
  notificationContent: [],
  notificationCount: "",
  successMessage: "",
  dialogMessage: "",
  contactMessage: "",
  contactCount: "",
  dialog: false,
  errorMessage: "",
  insertPreview: []
}


export {POST_CSV_DATA_ACTION} from '../container/Form/constants'

export const reducer = (state, action) => {
  switch(action.type) {
    case CLEAR_MESSAGE:
      return {
        ...state,
        successMessage: "",
        contactMessage: ""
      }
    case CONTACT_COUNT:
      return {
        ...state,
        successMessage: "",
        contactMessage: "",
        contactCount: action.payload
      }
    case DELETE_CONTACT:
      return {
        ...state,
        loading: true,
        deleteLoadingMessage: action.payload
      }
    case DELETE_RECORD_ACTION:
      return {
        ...state,
        loading: true,
        deleteLoadingMessage: action.payload
      }
    case DELETE_RECORD_END: 
      return {
        ...state,
        loading: true
      }
    case DELETE_RECORD_ERROR:
      return {
        ...state,
        insertLoadingMessage: "",
        deleteLoadingMessage: "",
        errorMessage: action.payload,
        succeessMessage: "",
        loading: false
      }
    case DELETE_RECORD_OK:
      return {
        ...state,
        loading: false,
        deleteLoadingMessage: action.payload
      }
    case FORM_INTERNAL_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      }
    case GET_NOTIFICATION_CONTENT:
      return {
        ...state,
        notificationContent: action.payload
      }
    case GET_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.payload
      }
    case INSERT_CONTACT: 
      return {
        ...state,
        loading: false,
        contactMessage: action.payload
      }
    case MANUAL_GENERATE_ACTION:
      return {
        ...state,
        dialog: true,
        loading: true, 
      }
    case MANUAL_GENERATE_SUCCESS:
      return {
        ...state,
        dialogMessage: action.payload,
        loading: false, 
      }
    case MANUAL_GENERATE_ERROR:
      return {
        ...state,
        dialogMessage: action.payload,
        loading: false, 
        dialog: true,
      }
    case MANUAL_GENERATE_END:
      return {
        ...state,
        loading: false, 
        dialog: false,
      }
    case POST_CSV_DATA_ACTION: 
      return {
        ...state,
        loading: true,
        successMessage: "",
        insertLoadingMessage: action.payload
      }
    case POST_CSV_DATA_ERROR:
      return {
        ...state,
        loading: true
      }
    case POST_CSV_DATA_OK:
      return {
        ...state,
        insertLoadingMessage: "",
        deleteLoadingMessage: "",
        successMessage: action.payload,
        loading: false
      }
    case POST_CSV_DATA_END: 
      return {
        ...state,
        loading: true
      }
    case PREVIEW_DATA: 
      return {
        ...state,
        insertPreview: action.payload
      }
    case "VIEW_CONTACTS": {
      return {
        ...state,
        allContacts: action.payload
      }
    }
    case "SAVE_COOKIES":
      return {
        ...state,
        cookies: action.value
      }

    case SPINNER_KILLER_ACTION:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      }
    default: 
    return state
  }
}