import React from 'react'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  Grid
} from '@material-ui/core'

const Spinner = (props) => {
  return (<Grid container spacing={3} alignItems="center" alignContent="center" justify="center">
        <Grid item xs={12}>
          <Dialog
            open={props.loading} 
          >
            <DialogContent>
              <center>
                <p>{props.message1 || ""}</p>
                <p>{props.message2 || ""}</p>
                <CircularProgress />
                <p></p>
              </center>
            </DialogContent>
          </Dialog>
        </Grid>
  </Grid>)
}

export default Spinner