/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {Toolbar, Typography, Button, Badge, Popover, Paper, ListItem, ListItemAvatar, List, ListItemText, Divider, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import {Link} from 'react-router-dom'
import {Description} from '@material-ui/icons'
import { useStateValue } from '../../stateContext'
import './index.scss'
import {CronJob} from 'cron'
import { NotificationsNone } from '@material-ui/icons'
import {getNotificationAction, getNotificationContentAction, manualSuccess, manualEnd, manualError} from './action'
import {getBookingApi, getNotifRequest, searchMe, readNotificationRequest, manualGenerate} from './requirestApi'
import cronTime from 'cron-time-generator'
import InfiniteScroll from 'react-infinite-scroll-component'

const Header = () => {

  const midMonth = cronTime.everyMonthOn(16,0,0)
  const firstDay = cronTime.everyMonthOn(1,0,0)
  useEffect(() => {
    getNotification()
    getNotificationCount()
    /*Also uncomment this one to trigger auto login*/
    // login()
  }, [])

  const [anchorEl, setAnchorEl] = useState(null)
  // const [searchData, setSearchData] = useState("")
  

  /* This is for auto login, Commented this one out so that we don't need to change the code when we change the environment */
  /* Since each environment has difference credentials */
  /* You can uncomment this one if you want to use it*/
  // const login = async () => {
  //   const credentials = {
  //     method: 'post',
  //     j_username: process.env.REACT_APP_CI_USERNAME || 'Codev_demo',
  //     j_password: process.env.REACT_APP_CI_PASSWORD || 'Codev!2345'
  //     j_username: process.env.REACT_APP_CI_USERNAME || 'lwalters@sorenson.com',
  //     j_password: process.env.REACT_APP_CI_PASSWORD || 'Sorenson99!'
  //   }
  //   const result = await authenticate('auth/login/', credentials)
  //   if (result.status === 200) {
  //     dispatch({ type: "SAVE_COOKIES", value: result.data })
  //     window.sessionStorage.setItem("ci_cookies", JSON.stringify(result.data))
  //     // const session = JSON.parse(window.sessionStorage.getItem('ci_cookies'))
  //     // await getBookingApi('auth/generate-file/', {session})
  //   }
  // }

  const getNotification = async () => {
    const result = await getNotifRequest('get-notification/')
    const {notification} = result
    dispatch(getNotificationContentAction(notification))
  }

  const getNotificationCount = async () => {
    const result = await getNotifRequest('get-notification-count/')
    dispatch(getNotificationAction(result.notification))
  }

  const session = window.sessionStorage.getItem('ci_cookies')
  
  const [{cookies, notificationContent ,notificationCount, dialogMessage, dialog}, dispatch] = useStateValue()
  
  const lastDayOfTheMonth = new CronJob(midMonth, () => {
    getBookingApi('auth/generate-file/', {session})
  })

  lastDayOfTheMonth.start()
  
  const Day15thOfTheMonth = new CronJob(firstDay, () => {
    getBookingApi('auth/generate-file/', {session})
  })

  Day15thOfTheMonth.start()

  const onHandleLogout = () => {
    window.sessionStorage.removeItem("ci_cookies")
    dispatch({type: "SAVE_COOKIES", value: ""})
  }

  const badgeClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeDateToReadable = (payload) => {
    const date = new Date(payload)
    const split = date.toString().split(" ")
    const time = `${split[1]} ${split[2]} ${split[3]} ${split[4]}`
    return time
  }

  const readNotification = async (id) => {
    const result = await readNotificationRequest('read-notification/',{id})
    const {data} = result
    dispatch(getNotificationAction(data.count))
    dispatch(getNotificationContentAction(data.notification))
  }

  const open = Boolean(anchorEl);

  const fetchMore = async () => {

  }

  const onSearch = async (e) => {
    const result = await searchMe('search-file', {search: e.target.value})
    dispatch(getNotificationContentAction(result.notification))
  }

  const onGenerateManually = async () => {
    const session = JSON.parse(window.sessionStorage.getItem('ci_cookies'))
    const result = await manualGenerate('auth/manual-generate/', {session}, dispatch)
    if (result.status === 200) {
      dispatch(manualSuccess(result.message))
    } else {
      dispatch(manualError(result.message))
    }
  }

  const onCloseDialog = () => {
    dispatch(manualEnd())
  }
  return (
    <div>
    <Dialog
      open={dialog}
    >
       <DialogTitle id="alert-dialog-title">{"Generate file message."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => onCloseDialog()} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
    </Dialog>
    <Toolbar style={{backgroundColor: "#FFF"}}>
      <Typography variant="h6">
          <img  height="70px" src="https://logodix.com/logo/1961524.png" alt="sample logo"/>
      </Typography>
      { !!session || !!cookies ?
        <div>
          <Link to="/"><Button>Form</Button></Link>
          <Link to="/update-contacts"><Button>Contacts</Button></Link>
          <Button style={{marginLeft: 'auto'}} color="primary" onClick={() => onGenerateManually()}>
            Generate file
          </Button>
          <Button style={{marginLeft: 'auto'}} color="secondary" onClick={() => onHandleLogout()}>
            Logout
          </Button>
          <Link to="/">
            <Badge badgeContent={notificationCount} color="error" onClick={(e) => badgeClick(e)}>
              <NotificationsNone />
            </Badge>
            <Popover
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={() => handleClose()}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Paper 
                style={{padding: 10}}
                id="scrollable"
              >
                <TextField label="Search" variant="outlined" id="search-data" fullWidth onKeyPress={(e) => onSearch(e)}/>
                <InfiniteScroll
                  dataLength={notificationContent.length}
                  scrollableTarget="scrollable" 
                  loader={<h3>Loading...</h3>}
                  inverse={true}
                  hasMore={true}
                  next={fetchMore()}
                >
                  {notificationContent.map((e, k) => {
                    return (
                      <List className="notification-content">
                        <a  className={e.status===true ? "unread s3-link" : "read s3-link"} href={e.s3link} blank="_target" onClick={() => readNotification(e._id)}>
                          <ListItem className="list-item">
                            <ListItemAvatar>
                            <Description />  
                            </ListItemAvatar>
                            <ListItemText primary={e.filename} secondary={changeDateToReadable(e.createdAt)}/>
                          </ListItem>
                        </a>
                        <Divider component="li" />
                      </List>
                    )
                  })}
                </InfiniteScroll>
              </Paper>
            </Popover>
          </Link>
        </div>  
      : "" }
    </Toolbar>
    </div>
  )
}

export default Header
