import React, {Component} from 'react';
import {StateProvider} from './stateContext'
import { initialState, reducer } from './globalReducer'
import {
  BrowserRouter as Router, 
  Switch,
  Route,
} from 'react-router-dom'
import {AppBar} from '@material-ui/core'
import Header from './component/Header'
import Welcome from './container/Form';
import './App.css';
import UpdateContacts from './container/UpdateContacts';
import Login from './container/Login';


class App extends Component {
  render() {
    return (
      <StateProvider initialState={initialState} reducer={reducer}>
        <Router>
            <AppBar>
              <Header/>
            </AppBar>
          <Switch>
            <Route exact path="/">
              <Login/>
              <Welcome /> 
            </Route>
            <Route exact path="/update-contacts">
              <Login/>
              <UpdateContacts /> 
            </Route>
            <Route>
              <h1>Page not found</h1>
            </Route>
          </Switch>
        </Router>
      </StateProvider>
    );
  }
}

export default App;
